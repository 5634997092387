@import "./_components.css";
@import "./_custom.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-size: 16px;
    line-height: normal;
  }
  [hidden] {
    display: none !important;
  }
  h1,
  .h1 {
    @apply text-4xl;
  }
  h2,
  .h2 {
    @apply text-3xl;
  }
  h3,
  .h3 {
    @apply text-2xl;
  }
  h4,
  .h4 {
    @apply text-xl;
  }
  h5,
  .h5 {
    @apply text-lg;
  }
  h6,
  .h6 {
    @apply text-base;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    line-height: normal;
    margin-bottom: 0;
    @apply font-medium;
  }
  hr {
    width: 100%;
    height: 1px;
    border: none;
    opacity: 1;
  }
  input,
  textarea {
    border: none;
    background-color: transparent;
    @apply focus:outline-none;
  }
}
.btn-sm {
  padding: 0.45rem 0.65rem;
  @apply text-sm;
}

.form-control__suggestions > ul > li {
  @apply !p-2 cursor-pointer rounded-lg;
}
.form-control__suggestions > ul {
  @apply max-h-80 overflow-scroll;
}
.form-control__suggestions > ul > li:hover {
  background-color: #345eac1a;
  @apply text-primary;
}
.form-control__suggestions {
  @apply !top-5 !p-1 rounded-xl border border-gray-200 bg-white !min-w-52;
}
.form-control__input {
  min-height: 10rem;
  @apply p-2;
}
.form-control__suggestions__item--focused{
  background-color: #345eac1a;
  @apply text-primary;
}