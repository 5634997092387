/* Timeline */
.timeline,
.timeline-horizontal {
  list-style: none;
  position: relative;
}
.timeline .timeline-item {
  margin-bottom: 20px;
  position: relative;
}

.timeline .timeline-item:before,
.timeline .timeline-item:after {
  content: "";
  display: table;


}

.timeline .timeline-item:after {
  clear: both;
}

.timeline .timeline-item .timeline-badge {
  color: #fff;
  width: 25px;
  height: 25px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: 18px;
  margin-left: -25px;
  border: 3px solid #ffff;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline .timeline-item .timeline-badge i,
.timeline .timeline-item .timeline-badge .fa,
.timeline .timeline-item .timeline-badge .glyphicon {
  top: 2px;
  left: 0px;
}

.timeline .timeline-item .timeline-panel {
  position: relative;
  width: 46%;
  float: left;
  right: 16px;
  padding: 16px;
  border-radius: 12px;
}

.timeline .timeline-item .timeline-panel .timeline-title {
  margin-top: 0;
  font-size: 25px;
}

.timeline .timeline-item .timeline-panel .timeline-body>p,
.timeline .timeline-item .timeline-panel .timeline-body>ul {
  margin-bottom: 0;
  font-family: 'Cinzel', sans-serif;
  color: #a79898;
}

.timeline .timeline-item .timeline-panel .timeline-body>p+p {
  margin-top: 0px;
}

.timeline .timeline-item:last-child:nth-child(even) {
  float: right;
}

.timeline .timeline-item:nth-child(even) .timeline-panel {
  float: right;
  left: 16px;
}

.timeline .timeline-item:nth-child(even) .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-horizontal {
  list-style: none;
  position: relative;
  padding: 0px 0px 30px 0px;
  display: inline-block;
}

.timeline-horizontal .timeline-item {
  // display: table-cell;
  padding-left: 35px;
  width: 285px;
  min-width: 285px;
  float: none !important;
  padding-top: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}

.timeline-horizontal .timeline-item .timeline-panel {
  top: auto;
  display: inline-block;
  float: none !important;
  left: 0 !important;
  right: 0 !important;
  width: 100%;
  margin-bottom: 16px;
}

.timeline-horizontal .timeline-item .timeline-panel:before {
  top: auto;
  bottom: -16px;
  left: 28px !important;
  right: auto;
  border-right: 16px solid transparent !important;
  border-top: 16px solid #c0c0c0 !important;
  border-bottom: 0 solid #c0c0c0 !important;
  border-left: 16px solid transparent !important;
}

// .timeline-horizontal .timeline-item:before,
.timeline-horizontal .timeline-item:after {
  display: none;
}

.timeline-horizontal .timeline-item .timeline-badge {
  top: 0px;
  bottom: 0px;
  left: 20px;
}

.timeline-horizontal .timeline-item:not(:last-child):before {
  height: 100%;
  // right: 0;
  // bottom: 10px;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 6px;
  top: 0px;
}

.timeline-horizontal .timeline-item:first-child .timeline-label {
  left: 22px
}

.timeline-horizontal .timeline-item:not(:first-child , :last-child) .timeline-label {
  // top: auto;
  left: 100px;
  // right: 0px;
  // text-align: center;
  width: 100%;
}